import React, { useEffect, useRef, useState } from "react";
import BelegDataInput, { BelegDataInputOnSubmitType } from "../components/filecontent/BelegDataInput";
import MandantRapView from "../components/filecontent/MandantRapView";

// // Hook
// function usePrevious(value: any) {
//   // Taken from https://usehooks.com/usePrevious/
//   // The ref object is a generic container whose current property is mutable ...
//   // ... and can hold any value, similar to an instance property on a class
//   const ref = useRef();

//   // Store current value in ref
//   useEffect(() => {
//     ref.current = value;
//   }, [value]); // Only re-run if value changes

//   // Return previous value (happens before update in useEffect above)
//   return ref.current;
// }

// markup
const Page = () => {
  const [editMode, setEditMode] = useState(false);
  const [beleg, setBeleg] = useState(undefined as BelegType | undefined);

  const onSubmit: BelegDataInputOnSubmitType = (beleg) => {
    setBeleg(beleg);
    setEditMode(true);
  };

  return (
    <div>
      <h2>Interaktiv</h2>
      An dieser Stelle können Sie mit per Hand eingegebenen Daten die Rechnungsabgrenzungsposten einzelner Belege erstellen.
      <div className="text-center">
        {editMode && beleg ? (
          <MandantRapView startingBelege={[beleg]} editable={true} />
        ) : (
          <BelegDataInput className="max-w-3xl inline-block" onSubmit={onSubmit} showExtraFields={false} />
        )}
        {/*})} */}
      </div>
    </div>
  );
};

export default Page;
